export default {
  sanitize(sku = {}) {
    const {
      packingType,
      shuttleVanDuration,
      handyManDuration,
      outsideElevatorDuration,
    } = sku;

    if (packingType) {
      sku[packingType] = true;
      delete sku.packingType;
    }

    if (shuttleVanDuration) {
      sku[shuttleVanDuration] = true;
      delete sku.shuttleVanDuration;
      delete sku.isShuttleVanSelected;
    }

    if (handyManDuration) {
      sku[handyManDuration] = true;
      delete sku.handyManDuration;
      delete sku.isHandyManSelected;
    }

    if (outsideElevatorDuration) {
      sku[outsideElevatorDuration] = true;
      delete sku.outsideElevatorDuration;
      delete sku.isOutsideElevatorSelected;
    }

    delete sku.isLongWalkSelected;
    delete sku.isStorageSelected;
    delete sku.isStairsCarrySelected;
    delete sku.isLiftSelected;

    Object.keys(sku).forEach(key => {
      if (!sku[key] || (typeof sku[key] === 'object' && !sku[key].value)) {
        delete sku[key];
      }
    });

    return sku;
  },
};
