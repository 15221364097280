import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const isHidden = value !== index;

  return (
    <Typography
      aria-labelledby={`simple-tab-${index}`}
      component="div"
      hidden={isHidden}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {!isHidden && children}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  index: PropTypes.number,
  value: PropTypes.number,
};
