import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';

import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  underlined: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    paddingBottom: theme.spacing(1),
  },
  input: {
    marginLeft: theme.spacing(1),
  },
}));

export default function StorageForm(props) {
  const { watch, errors, control, register } = useFormContext();
  const classes = useStyles();
  const isStorageSelected = watch('sku.isStorageSelected');

  function getErrorMessage(property) {
    if (errors && 'sku' in errors && errors.sku[property]) {
      return errors.sku[property].message;
    }
  }

  return (
    <Box {...props}>
      <Typography className={classes.underlined} gutterBottom variant="h5">
        STORAGE
      </Typography>
      <FormGroup aria-label="position">
        <Controller
          name="sku.storage:handling_in_out"
          control={control}
          as={
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="Handling in and out"
            />
          }
        />
        <Grid alignItems="center" container>
          <Grid item>
            <Controller
              name="sku.isStorageSelected"
              control={control}
              as={
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="Storage"
                />
              }
            />
          </Grid>
          {isStorageSelected && (
            <Grid item>
              <TextField
                name="sku.storage:weeks"
                aria-describedby="storage-helper"
                size="small"
                label="Weeks"
                type="number"
                inputRef={register}
                variant="outlined"
                error={Boolean(getErrorMessage('storage:weeks'))}
                helperText={getErrorMessage('storage:weeks')}
              />
            </Grid>
          )}
        </Grid>
      </FormGroup>
    </Box>
  );
}
