import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Switch,
  TextField,
  Typography,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import VolumeForm from './VolumeForm';

const useStyles = makeStyles(theme => ({
  doubleGutter: {
    marginBottom: theme.spacing(4),
  },
  underlined: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    paddingBottom: theme.spacing(1),
  },
}));

export default function PackingForm(props) {
  const { control, watch, register } = useFormContext();
  const classes = useStyles();
  const activePackingOption = watch('sku.packingType');
  const isBlanketWrapSelected =
    activePackingOption === 'package:blanket_wrap_packing';

  return (
    <Box {...props}>
      <Typography className={classes.underlined} gutterBottom variant="h5">
        PACKING OPTIONS
      </Typography>
      <FormControl component="fieldset" fullWidth>
        <Controller
          name="sku.packingType"
          control={control}
          as={
            <RadioGroup aria-label="Route Type">
              <Grid container justify="space-between">
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Owner Packed Goods"
                    value="package:owner_packed"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Full export pack and wrap with unpacking and removal of debris."
                    value="package:export"
                  />
                </Grid>
                <Grid container item xs={12}>
                  <Grid item>
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Blanket wrap of furniture with packing service (based on 30% smalls, 70% furniture)"
                      value="package:blanket_wrap_packing"
                    />
                  </Grid>
                  {isBlanketWrapSelected && (
                    <Grid item>
                      <Box ml={3}>
                        <Controller
                          name="sku.package:unpacking"
                          control={control}
                          as={
                            <FormControlLabel
                              style={{ margin: 0 }}
                              control={<Checkbox color="primary" />}
                              label="Unpacking service"
                            />
                          }
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Custom"
                    value="package:custom"
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          }
        />
        {activePackingOption === 'package:custom' && (
          <Box ml={4} mt={2}>
            <Typography
              className={clsx(classes.underlined, classes.doubleGutter)}
              gutterBottom
              variant="h5"
            >
              EXPORT WRAPPING SERVICE
            </Typography>
            <Grid container spacing={4}>
              <Grid
                alignItems="center"
                container
                direction="row"
                item
                justify="flex-start"
                spacing={2}
              >
                <VolumeForm ml={1} name="sku.package:export_wrap" />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={clsx(classes.underlined, classes.doubleGutter)}
                  gutterBottom
                  variant="h5"
                >
                  STANDARD PACKING SERVICE
                </Typography>
                <Grid container direction="column" item spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      color="secondary"
                      name="sku.package:book_carton"
                      inputRef={register}
                      fullWidth
                      inputProps={{ min: 0, step: 1 }}
                      label="Book Carton - Pack 1"
                      size="small"
                      type="number"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      color="secondary"
                      name="sku.package:standard_carton"
                      inputRef={register}
                      fullWidth
                      inputProps={{ min: 0, step: 1 }}
                      label="Standard Carton - Pack 2"
                      size="small"
                      type="number"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      color="secondary"
                      name="sku.package:large_carton"
                      inputRef={register}
                      fullWidth
                      inputProps={{ min: 0, step: 1 }}
                      label="Large Carton - Pack 3"
                      size="small"
                      type="number"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      color="secondary"
                      name="sku.package:linen_carton"
                      inputRef={register}
                      fullWidth
                      inputProps={{ min: 0, step: 1 }}
                      label="Linen Carton - Pack 4"
                      size="small"
                      type="number"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      color="secondary"
                      name="sku.package:wardrobe_carton"
                      inputRef={register}
                      fullWidth
                      inputProps={{ min: 0, step: 1 }}
                      label="Wardrobe Carton"
                      size="small"
                      type="number"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Controller
                      name="sku.package:custom_unpacking"
                      control={control}
                      as={
                        <FormControlLabel
                          control={<Switch color="primary" />}
                          label="Unpacking service:"
                          labelPlacement="start"
                          size="small"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </FormControl>
    </Box>
  );
}
