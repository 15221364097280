import * as yup from 'yup';
import VolumeForm from '../partials/VolumeForm';

export default yup.object().shape({
  volume: VolumeForm.schema,
  origin: yup.string().required('Please select origin.'),
  destination: yup.string().required('Please select destination.'),
  termsAccepted: yup.bool(),
  sku: yup.object().shape({
    isLongWalkSelected: yup.bool(),
    isShuttleVanSelected: yup.bool(),
    isStorageSelected: yup.bool(),
    isOutsideElevatorSelected: yup.bool(),
    isStairsCarrySelected: yup.bool(),
    isLiftSelected: yup.bool(),
    isDoubleWardrobeSelected: yup.bool(),
    isTripleWardrobeSelected: yup.bool(),
    isTrampolineSelected: yup.bool(),
    isHandyManSelected: yup.bool(),
    packingType: yup.string(),
    'package:export_wrap': yup.object().shape({
      value: yup.string(),
      measurement: yup.string(),
    }),
    'package:book_carton': yup.string(),
    'package:standard_carton': yup.string(),
    'package:large_carton': yup.string(),
    'package:linen_carton': yup.string(),
    'package:custom_unpacking': yup.bool(),
    'access:parking_permit': yup.bool(),
    'access:lift': yup.number().when('isLiftSelected', {
      is: true,
      then: yup
        .number()
        .typeError('Please enter floor')
        .moreThan(1, 'Floor should be greater than 1')
        .required('Please enter floor'),
      otherwise: yup.number(),
    }),
    'access:stairs_carry': yup.number().when('isStairsCarrySelected', {
      is: true,
      then: yup
        .number()
        .typeError('Please enter floor')
        .moreThan(1, 'Floor should be greater than 1')
        .required('Please enter floor'),
      otherwise: yup.number(),
    }),
    'access:long_walk': yup.number().when('isLongWalkSelected', {
      is: true,
      then: yup
        .number()
        .typeError('Please enter distance')
        .moreThan(20, 'Long walk supposed to be over 20m')
        .required('Please enter distance'),
      otherwise: yup.number(),
    }),
    'storage:weeks': yup.string().when('isStorageSelected', {
      is: true,
      then: yup.string().required('Please enter amount of weeks'),
      otherwise: yup.string(),
    }),
    'assembly:double_wardrobe': yup.number().when('isDoubleWardrobeSelected', {
      is: true,
      then: yup
        .number()
        .typeError('Please enter amount')
        .moreThan(0, 'Amount should be greater than 0')
        .required('Please enter amount'),
      otherwise: yup.number(),
    }),
    'assembly:triple_wardrobe': yup.number().when('isTripleWardrobeSelected', {
      is: true,
      then: yup
        .number()
        .typeError('Please enter amount')
        .moreThan(0, 'Amount should be greater than 0')
        .required('Please enter amount'),
      otherwise: yup.number(),
    }),
    'assembly:trampoline': yup.number().when('isTrampolineSelected', {
      is: true,
      then: yup
        .number()
        .typeError('Please enter amount')
        .moreThan(0, 'Amount should be greater than 0')
        .required('Please enter amount'),
      otherwise: yup.number(),
    }),
  }),
});
