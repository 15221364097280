import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout } from './layouts';

import {
  Calculator as CalculatorView,
  NotFound as NotFoundView,
  TermsAndConditions as TermsAndConditionsView,
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/calculator" />
      <RouteWithLayout
        component={CalculatorView}
        exact
        layout={MainLayout}
        path="/calculator"
      />
      <RouteWithLayout
        component={TermsAndConditionsView}
        exact
        layout={MainLayout}
        path="/terms-and-conditions"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MainLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
