import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  underlined: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    paddingBottom: theme.spacing(1),
  },
}));

export default function PianoAndSafeForm(props) {
  const classes = useStyles();
  const { control } = useFormContext();
  return (
    <Box {...props}>
      <Typography className={classes.underlined} gutterBottom variant="h5">
        PIANOS / SAFES
      </Typography>
      <Typography gutterBottom variant="subtitle2">
        Please include volume of piano / safe in submission
      </Typography>
      <FormGroup aria-label="position">
        <Controller
          name="sku.pianos_and_safes:upright_piano"
          control={control}
          as={
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="Upright Piano"
            />
          }
        />
        <Controller
          name="sku.pianos_and_safes:baby_grand_piano"
          control={control}
          as={
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="Baby Grand Piano"
            />
          }
        />
        <Controller
          name="sku.pianos_and_safes:grand_piano"
          control={control}
          as={
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="Grand Piano"
            />
          }
        />
        <Controller
          name="sku.pianos_and_safes:safe_up_to_200_kg"
          control={control}
          as={
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="Safes < 200 kg"
            />
          }
        />
        <Controller
          name="sku.pianos_and_safes:safe_up_to_350_kg"
          control={control}
          as={
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="Safes < 350 kg"
            />
          }
        />
      </FormGroup>
    </Box>
  );
}
