import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Checkbox,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  underlined: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    paddingBottom: theme.spacing(1),
  },
}));

export default function AssemblyForm(props) {
  const { watch, errors, control, register } = useFormContext();
  const classes = useStyles();

  const isDoubleWardrobeSelected = watch('sku.isDoubleWardrobeSelected');
  const isTripleWardrobeSelected = watch('sku.isTripleWardrobeSelected');
  const isTrampolineSelected = watch('sku.isTrampolineSelected');
  const isHandyManSelected = watch('sku.isHandyManSelected');

  function getErrorMessage(property) {
    if (errors && 'sku' in errors && errors.sku[property]) {
      return errors.sku[property].message;
    }
  }

  return (
    <Box {...props}>
      <Typography className={classes.underlined} gutterBottom variant="h5">
        ASSEMBLY / DISASSEMBLY
      </Typography>
      <Typography gutterBottom variant="subtitle2">
        Rates based on standard assembly and dissasembly of beds. Does not
        include IKEA or modular furniture.
      </Typography>
      <FormGroup aria-label="position">
        <Grid container alignItems="center">
          <Grid item>
            <Controller
              name="sku.isDoubleWardrobeSelected"
              control={control}
              as={
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="Double Wardrobe"
                />
              }
            />
          </Grid>
          <Grid item>
            {isDoubleWardrobeSelected && (
              <TextField
                name="sku.assembly:double_wardrobe"
                aria-describedby="double-wardrobe-helper"
                inputRef={register}
                size="small"
                type="number"
                error={Boolean(getErrorMessage('assembly:double_wardrobe'))}
                helperText={getErrorMessage('assembly:double_wardrobe')}
                label="Amount"
                variant="outlined"
              />
            )}
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item>
            <Controller
              name="sku.isTripleWardrobeSelected"
              control={control}
              as={
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="Triple Wardrobe"
                />
              }
            />
          </Grid>
          <Grid item>
            {isTripleWardrobeSelected && (
              <TextField
                name="sku.assembly:triple_wardrobe"
                aria-describedby="triple-wardrobe-helper"
                inputRef={register}
                size="small"
                type="number"
                error={Boolean(getErrorMessage('assembly:triple_wardrobe'))}
                helperText={getErrorMessage('assembly:triple_wardrobe')}
                label="Amount"
                variant="outlined"
              />
            )}
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item>
            <Controller
              name="sku.isTrampolineSelected"
              control={control}
              as={
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="Trampoline"
                />
              }
            />
          </Grid>
          <Grid item>
            {isTrampolineSelected && (
              <TextField
                name="sku.assembly:trampoline"
                aria-describedby="double_wardrobe-helper"
                size="small"
                type="number"
                inputRef={register}
                error={Boolean(getErrorMessage('assembly:trampoline'))}
                helperText={getErrorMessage('assembly:trampoline')}
                label="Amount"
                variant="outlined"
              />
            )}
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item>
            <Controller
              name="sku.isHandyManSelected"
              control={control}
              as={
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="Handy Man"
                />
              }
            />
          </Grid>
          <Grid item>
            {isHandyManSelected && (
              <FormControl component="fieldset">
                <Controller
                  name="sku.handyManDuration"
                  control={control}
                  as={
                    <RadioGroup aria-label="volume">
                      <Grid container>
                        <Grid item>
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Half Day"
                            value="assembly:handy_man_half_day"
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Full Day"
                            value="assembly:handy_man_full_day"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  }
                />
              </FormControl>
            )}
          </Grid>
        </Grid>
      </FormGroup>
    </Box>
  );
}
