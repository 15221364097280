import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import Alert from '@material-ui/lab/Alert';
import { Grid, Box, Paper, Snackbar } from '@material-ui/core';

import api from 'services/@core/api';
import skuService from 'services/sku';

import LocationForm from '../partials/LocationForm';
import VolumeForm from '../partials/VolumeForm';
import PackingForm from '../partials/PackingForm';
import AccessForm from '../partials/AccessForm';
import PianoAndSafeForm from '../partials/PianoAndSafeForm';
import StorageForm from '../partials/StorageForm';
import VehiclesForm from '../partials/VehiclesForm';
import ServiceSelectionForm from '../partials/ServiceSelectionForm';
import FormControls from '../partials/FormControls';
import PaymentOptionsForm from '../partials/PaymentOptionsForm';
import AssemblyForm from '../partials/AssemblyForm';

import AlertDialog from 'components/AlertDialog';
import defaultValues from './defaultValues';
import validationSchema from './schema';
import useStyles from './styles';
import SupportedCountries from 'components/SupportedCountries';

const services = [
  {
    type: 'dedicated',
    label: 'Dedicated load',
  },
  {
    type: 'part',
    label: 'Part load',
  },
];

const NationalCalculatorForm = () => {
  const methods = useForm({ defaultValues, validationSchema });
  const classes = useStyles();

  const { handleSubmit, watch } = methods;
  const fullHeightPaper = clsx(classes.Paper, classes.fullHeight);
  const [quoteErrorMessage, setQuoteErrorMessage] = useState(null);
  const [cost, setCost] = useState(0);
  const isTermsAccepted = watch('termsAccepted');

  const handleAlertClose = () => {
    setQuoteErrorMessage(null);
  };

  const [
    isTermsAndConditionsAlertOpen,
    setIsTermsAndConditionsAlertOpen,
  ] = useState(false);

  const onTermsAlertClose = () => {
    setIsTermsAndConditionsAlertOpen(false);
  };

  const onReset = useCallback(() => {
    setCost(0);
  }, []);

  const [isCalculating, setIsCalculating] = useState(false);

  const onSubmit = async ({ sku, ...restFormData }) => {
    if (!isTermsAccepted) {
      return setIsTermsAndConditionsAlertOpen(true);
    }

    setCost(0);
    setIsCalculating(true);

    const payload = {
      ...restFormData,
      sku: skuService.sanitize(sku),
      type: 'national',
    };

    api
      .generateQuote(payload)
      .then(function({ data }) {
        setCost(data.quote.cost.toFixed());
        setIsCalculating(false);
      })
      .catch(function(error) {
        if (error.response) {
          setQuoteErrorMessage(error.response.data.error.message);
        } else {
          setQuoteErrorMessage(
            'Error occurred during calculation, please contact our call center',
          );
        }
        setIsCalculating(false);
      });
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SupportedCountries countries={['Ireland', 'Northern Ireland']} />
        <Box mb={4}>
          <Paper className={classes.Paper}>
            <VolumeForm mb={2} name="volume" />
            <LocationForm destination mb={2} countries={['ie', 'gb']} origin />
            <ServiceSelectionForm services={services} />
          </Paper>
        </Box>
        <Box mb={4}>
          <Paper className={classes.Paper}>
            <PaymentOptionsForm />
          </Paper>
        </Box>
        <Box mb={4}>
          <Paper className={classes.Paper}>
            <PackingForm />
          </Paper>
        </Box>
        <Box mb={4}>
          <Grid alignContent="stretch" container spacing={4}>
            <Grid item lg={3} md={6} xs={12}>
              <Paper className={fullHeightPaper}>
                <AccessForm />
              </Paper>
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <Paper className={fullHeightPaper}>
                <PianoAndSafeForm />
              </Paper>
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <Paper className={fullHeightPaper}>
                <VehiclesForm />
              </Paper>
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <Paper className={fullHeightPaper}>
                <StorageForm section="national" />
              </Paper>
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <Paper className={fullHeightPaper}>
                <AssemblyForm />
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <FormControls
          cost={cost}
          defaultValues={defaultValues}
          onReset={onReset}
          isCalculating={isCalculating}
        />
      </form>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={10000}
        onClose={handleAlertClose}
        open={Boolean(quoteErrorMessage)}
      >
        <Alert onClose={handleAlertClose} severity="error" variant="filled">
          {quoteErrorMessage}
        </Alert>
      </Snackbar>
      <AlertDialog
        title="Please accept the Prolink standard: Terms&Conditions"
        onClose={onTermsAlertClose}
        open={isTermsAndConditionsAlertOpen}
      />
    </FormContext>
  );
};

export default NationalCalculatorForm;
