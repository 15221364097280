import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Typography,
  Paper,
  Tabs,
  Tab,
  useMediaQuery,
} from '@material-ui/core';

import { TabPanel } from '../../components';
import {
  EuropeanCalculatorForm,
  NationalCalculatorForm,
  ImportCalculatorForm,
  ExportCalculatorForm,
  ServiceInfo,
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#e3e3e3',
    padding: theme.spacing(4),
  },
  tabsWrapper: {
    flexGrow: 1,
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabsHolder: {
    backgroundColor: theme.palette.white,
    marginBottom: theme.spacing(3),
  },
}));

const Calculator = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState(0);
  const xs = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box className={classes.root}>
      {/* <Box mb={2}>
        <Typography align="center" gutterBottom variant="h1">
          Removals Cost Calculator
        </Typography>
        <ServiceInfo />
      </Box> */}
      <Box className={classes.tabsWrapper}>
        <Paper className={classes.tabsHolder}>
          <Tabs
            centered
            className={classes.TabsComponent}
            indicatorColor="secondary"
            onChange={handleTabChange}
            textColor="secondary"
            value={activeTab}
            {...(xs && {
              orientation: 'vertical',
              variant: 'fullWidth',
            })}
          >
            <Tab label="National" />
            <Tab label="European Roads" />
            <Tab label="Import" />
            <Tab label="Export" />
          </Tabs>
        </Paper>
        <Box>
          <TabPanel index={0} value={activeTab}>
            <NationalCalculatorForm />
          </TabPanel>
          <TabPanel index={1} value={activeTab}>
            <EuropeanCalculatorForm />
          </TabPanel>
          <TabPanel index={2} value={activeTab}>
            <ImportCalculatorForm />
          </TabPanel>
          <TabPanel index={3} value={activeTab}>
            <ExportCalculatorForm />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default Calculator;
