import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  Paper: {
    backgroundColor: theme.palette.white,
    padding: 20,
  },
  Total: {
    background: '#cd1b1b',
    lineHeight: '36px',
    color: '#fff',
    padding: '0 20px',
    fontFamily: theme.typography.fontFamily,
    textAlign: 'right',
    height: '36px',
    borderRadius: '4px 0 0 4px',
  },
  Sum: {
    background: '#a70805',
    padding: '0 20px',
    color: '#fff',
    fontFamily: theme.typography.fontFamily,
    textAlign: 'left',
    height: '36px',
    borderRadius: '0 4px 4px 0',
    '& span': {
      display: 'inline-block',
      lineHeight: '36px',
    },
  },
  total: {
    padding: '8px 16px',
    margin: '0 -8px',
  },
  underlined: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  fullHeight: {
    height: '100%',
  },
  resetButton: {
    backgroundColor: '#ff9800',
    '&:hover': {
      backgroundColor: '#f44336',
    },
  },
  dialog: {
    '& h2': {
      fontSize: 18,
      fontWeight: 700,
    },
  },
}));
