import React from 'react';
import { Box, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { useFormContext, Controller } from 'react-hook-form';

export default function HaulageForm(props) {
  const { control } = useFormContext();

  return (
    <Box {...props}>
      <FormGroup aria-label="position" row>
        <Controller
          name="haulage"
          control={control}
          as={
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="Haulage"
            />
          }
        />
        <Controller
          name="clearance"
          control={control}
          as={
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="Clearance"
            />
          }
        />
      </FormGroup>
    </Box>
  );
}
