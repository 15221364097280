import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Checkbox,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  underlined: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    paddingBottom: theme.spacing(1),
  },
}));

export default function AccessForm(props) {
  const { watch, errors, control, register } = useFormContext();
  const classes = useStyles();

  const isLongWalkSelected = watch('sku.isLongWalkSelected');
  const isShuttleVanSelected = watch('sku.isShuttleVanSelected');
  const isOutsideElevatorSelected = watch('sku.isOutsideElevatorSelected');
  const isLiftSelected = watch('sku.isLiftSelected');
  const isStairsCarrySelected = watch('sku.isStairsCarrySelected');

  function getErrorMessage(property) {
    if (errors && 'sku' in errors && errors.sku[property]) {
      return errors.sku[property].message;
    }
  }

  return (
    <Box {...props}>
      <Typography className={classes.underlined} gutterBottom variant="h5">
        ACCESS
      </Typography>
      <Typography gutterBottom variant="subtitle2">
        Rates based on max 1st floor with normal access and parking for a 12
        meter truck no further than 20 meters from door of residence.
      </Typography>
      <FormGroup aria-label="position">
        <Grid container alignItems="center">
          <Grid item>
            <Controller
              name="sku.isLongWalkSelected"
              control={control}
              as={
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="Long Walk"
                />
              }
            />
          </Grid>
          <Grid item>
            {isLongWalkSelected && (
              <TextField
                name="sku.access:long_walk"
                inputRef={register}
                aria-describedby="long-walk-helper"
                size="small"
                type="number"
                error={Boolean(getErrorMessage('access:long_walk'))}
                helperText={getErrorMessage('access:long_walk')}
                label="Distance in meters"
                variant="outlined"
              />
            )}
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item>
            <Controller
              name="sku.isLiftSelected"
              control={control}
              as={
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="Lift"
                />
              }
            />
          </Grid>
          <Grid item>
            {isLiftSelected && (
              <TextField
                name="sku.access:lift"
                inputRef={register}
                aria-describedby="lift-helper"
                size="small"
                type="number"
                error={Boolean(getErrorMessage('access:lift'))}
                helperText={getErrorMessage('access:lift')}
                label="Floor"
                variant="outlined"
              />
            )}
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item>
            <Controller
              name="sku.isStairsCarrySelected"
              control={control}
              as={
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="Stairs Carry"
                />
              }
            />
          </Grid>
          <Grid item>
            {isStairsCarrySelected && (
              <TextField
                name="sku.access:stairs_carry"
                inputRef={register}
                aria-describedby="stairs_carry-helper"
                size="small"
                type="number"
                error={Boolean(getErrorMessage('access:stairs_carry'))}
                helperText={getErrorMessage('access:stairs_carry')}
                label="Floor"
                variant="outlined"
              />
            )}
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item>
            <Controller
              name="sku.isShuttleVanSelected"
              control={control}
              as={
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="Shuttle Van"
                />
              }
            />
          </Grid>
          <Grid item>
            {isShuttleVanSelected && (
              <FormControl component="fieldset">
                <Controller
                  name="sku.shuttleVanDuration"
                  control={control}
                  as={
                    <RadioGroup aria-label="volume">
                      <Grid container>
                        <Grid item>
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Half Day"
                            value="access:shuttle_van_half_day"
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Full Day"
                            value="access:shuttle_van_full_day"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  }
                />
              </FormControl>
            )}
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item>
            <Controller
              name="sku.isOutsideElevatorSelected"
              control={control}
              as={
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="Outside Elevator"
                />
              }
            />
          </Grid>
          <Grid item>
            {isOutsideElevatorSelected && (
              <FormControl component="fieldset">
                <Controller
                  name="sku.outsideElevatorDuration"
                  control={control}
                  as={
                    <RadioGroup aria-label="volume">
                      <Grid container>
                        <Grid item>
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Half Day"
                            value="access:outside_elevator_half_day"
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Full Day"
                            value="access:outside_elevator_full_day"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  }
                />
              </FormControl>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Controller
            name="sku.access:parking_permit"
            control={control}
            as={
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="Parking Permit"
              />
            }
          />
        </Grid>
      </FormGroup>
    </Box>
  );
}
