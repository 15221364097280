import React, { useState, useCallback, useEffect, useRef } from 'react';
import { TextField, Grid, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import useGoogleMapsSDK from 'hooks/useGoogleMapsSDK';
import useGoogleAutocomplete from 'hooks/useGoogleAutocomplete';

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  color: theme.palette.white,
  w100: {
    width: '100%',
  },
}));

export default function PlacesAutocomplete(props) {
  const { name, error, label, countries, register, value, onChange } = props;
  const classes = useStyles();

  useGoogleMapsSDK();

  useEffect(() => {
    register({ name, focus: onFocus });
  }, [register, name]);

  const componentRestrictions = { country: countries };
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef();
  const onFocus = () => {
    inputRef.current.focus();
  };

  const [options] = useGoogleAutocomplete({
    inputValue,
    componentRestrictions,
  });

  const getDescription = useCallback(value => {
    if (value) {
      return typeof value === 'string' ? value : value.description;
    }
    return '';
  }, []);

  const handleInputChange = useCallback(event => {
    setInputValue(event.target.value);
  }, []);

  return (
    <Autocomplete
      size="small"
      autoComplete
      value={value}
      openOnFocus={false}
      includeInputInList
      options={options}
      getOptionLabel={getDescription}
      getOptionSelected={(option, value) => {
        if (!value) return true;
        return getDescription(option) === value;
      }}
      className={classes.w100}
      filterOptions={x => x}
      onChange={(event, value) => {
        onChange(getDescription(value), true);
      }}
      renderInput={params => (
        <TextField
          {...params}
          color="secondary"
          inputRef={inputRef}
          fullWidth
          error={Boolean(error)}
          helperText={Boolean(error) && error.message}
          label={label || 'Add Location'}
          onChange={handleInputChange}
          variant="outlined"
        />
      )}
      renderOption={option => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map(match => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid alignItems="center" container>
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
              <Typography color="textSecondary" variant="body2">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
