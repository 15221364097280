import { useEffect, useMemo, useRef, useState } from 'react';
import throttle from 'lodash.throttle';

export default ({ inputValue, componentRestrictions }) => {
  const autocompleteService = useRef(null);
  const [results, setResults] = useState([]);
  const fetch = useMemo(
    () =>
      throttle((input, callback) => {
        autocompleteService.current.getPlacePredictions(input, callback);
      }, 200),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setResults([]);
      return undefined;
    }

    const requestOptions = { input: inputValue };

    if (
      componentRestrictions &&
      componentRestrictions.country &&
      componentRestrictions.country.length > 0
    ) {
      requestOptions.componentRestrictions = componentRestrictions;
    }

    fetch(requestOptions, results => {
      if (active) {
        setResults(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return [results];
};
