import React from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { PlacesAutocomplete } from 'components';
import * as yup from 'yup';

LocationForm.schema = yup.object({
  origin: yup.string().required('Please select origin.'),
  destination: yup.string().required('Please select destination.'),
});

const useStyles = makeStyles(theme => ({
  supportedCountries: {
    paddingTop: '10px',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0.5),
      fontWeight: 'bold',
    },
  },
}));

function LocationForm({ origin, destination, countries, ...otherProps }) {
  const { errors, register, watch, setValue } = useFormContext();

  const classes = useStyles();
  const originValue = watch('origin');
  const destinationValue = watch('destination');

  const onChange = name => (value, shouldValidate) => {
    setValue(name, value, shouldValidate);
  };

  return (
    <Box {...otherProps}>
      <Typography gutterBottom variant="h5">
        ENTER POSTCODE OR TOWN BELOW:
      </Typography>
      <Grid alignContent="space-around" container spacing={2}>
        {origin && (
          <Grid item md={6} xs={12}>
            <PlacesAutocomplete
              name="origin"
              label="Origin"
              register={register}
              value={originValue}
              onChange={onChange('origin')}
              countries={countries}
              error={errors.origin}
            />
          </Grid>
        )}
        {destination && (
          <Grid item md={6} xs={12}>
            <PlacesAutocomplete
              name="destination"
              label="Destination"
              register={register}
              value={destinationValue}
              onChange={onChange('destination')}
              error={errors.destination}
              countries={countries}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

LocationForm.propTypes = {
  destination: PropTypes.bool,
  origin: PropTypes.bool,
};

export default LocationForm;
