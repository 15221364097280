import React from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Box,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';

import { useFormContext, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  doubleGutter: {
    marginBottom: theme.spacing(4),
  },
  underlined: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    paddingBottom: theme.spacing(1),
  },
  input: {
    marginLeft: theme.spacing(1),
  },
}));

export default function ServiceSelectionForm({ services, ...props }) {
  const { control } = useFormContext();
  const classes = useStyles();

  return (
    <Box {...props}>
      <Typography className={classes.underlined} gutterBottom variant="h5">
        SERVICE
      </Typography>
      <FormControl component="fieldset" fullWidth>
        <Controller
          name="service"
          control={control}
          as={
            <RadioGroup aria-label="Route Type">
              <Grid container>
                {services.map(service => {
                  return (
                    <Grid item key={service.type} md={3} sm={6} xs={12}>
                      <FormControlLabel
                        control={<Radio color="primary" />}
                        label={service.label}
                        value={service.type}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </RadioGroup>
          }
        />
      </FormControl>
    </Box>
  );
}
