import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: '#25323E',
    dark: '#940000',
    main: '#cd1b1b',
    light: '#ff5845'
  },
  secondary: {
    contrastText: white,
    dark: '#26323e',
    main: '#41576c',
    light: '#41576c'
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: '#1B1B1B',
    secondary: '#41576c',
    link: '#333'
  },
  background: {
    default: '#e3e3e3',
    paper: '#e3e3e3',
  },
  icon: white,
  divider: colors.grey[200]
};
