import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  underlined: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    paddingBottom: theme.spacing(1),
  },
}));

export default function PaymentOptionsForm(props) {
  const { control } = useFormContext();
  const classes = useStyles();

  return (
    <Box {...props}>
      <Typography className={classes.underlined} gutterBottom variant="h5">
        PAYMENT OPTIONS
      </Typography>
      <Typography gutterBottom variant="subtitle2">
        Get 5% discount by paying on collection
      </Typography>
      <FormGroup aria-label="position">
        <Controller
          name="discounts.pay_on_collection"
          control={control}
          as={
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="Pay on collection"
            />
          }
        />
      </FormGroup>
    </Box>
  );
}
