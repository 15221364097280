import { useRef } from 'react';
import { loadScript } from 'helpers';

export default () => {
  const id = 'google-maps-sdk';
  const isLoadedRef = useRef(false);
  const scriptNode = document.querySelector(`#${id}`);

  if (typeof window !== 'undefined' && !isLoadedRef.current) {
    if (!scriptNode) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        id,
      );
    }

    isLoadedRef.current = true;
  }
};
