import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { Fragment, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useFormContext, Controller } from 'react-hook-form';
import { useConfirm } from 'material-ui-confirm';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  Total: {
    background: '#cd1b1b',
    lineHeight: '36px',
    color: '#fff',
    padding: '0 20px',
    fontFamily: theme.typography.fontFamily,
    textAlign: 'right',
    height: '36px',
    borderRadius: '4px 0 0 4px',
  },
  Sum: {
    background: '#a70805',
    padding: '0 20px',
    color: '#fff',
    fontFamily: theme.typography.fontFamily,
    textAlign: 'left',
    height: '36px',
    borderRadius: '0 4px 4px 0',
    '& span': {
      display: 'inline-block',
      lineHeight: '36px',
    },
  },
  resetButton: {
    backgroundColor: '#ff9800',
    '&:hover': {
      backgroundColor: '#f44336',
    },
  },
  dialog: {
    '& h2': {
      fontSize: 18,
      fontWeight: 700,
    },
  },
}));

export default function FormControls({
  cost,
  defaultValues,
  onReset,
  isCalculating,
}) {
  const { control, formState, reset } = useFormContext();
  const { dirty } = formState;
  const confirm = useConfirm();
  const classes = useStyles();

  const resetForm = useCallback(() => {
    confirm({
      title: 'Are you sure you want to reset the form?',
      description: null,
      confirmationText: 'Reset',
      dialogProps: {
        maxWidth: 'xs',
      },
      confirmationButtonProps: {
        size: 'small',
        variant: 'contained',
        color: '#fff',
      },
      cancellationButtonProps: {
        size: 'small',
        variant: 'contained',
        color: 'secondary',
      },
    }).then(
      () => {
        reset(defaultValues);
        onReset();
      },
      () => {},
    );
  }, [confirm, reset, classes.dialog]);

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item sm>
        <Controller
          name="termsAccepted"
          control={control}
          as={
            <FormControlLabel
              control={<Switch color="primary" />}
              label={
                <Fragment>
                  I accept the Prolink standard:
                  <RouterLink to="/terms-and-conditions">
                    Terms &Conditions
                  </RouterLink>
                </Fragment>
              }
              labelPlacement="start"
            />
          }
        />
      </Grid>
      <Grid
        alignContent="stretch"
        container
        item
        justify="space-around"
        md
        spacing={2}
      >
        <Grid
          alignContent="stretch"
          container
          item
          justify="space-between"
          sm={6}
          spacing={2}
          xs={12}
        >
          <Grid item md={6} xs={12}>
            <Button
              color="secondary"
              fullWidth
              type="submit"
              variant="contained"
            >
              Generate Quote
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              color="secondary"
              fullWidth
              type="button"
              disabled={!dirty}
              variant="contained"
              onClick={resetForm}
              className={classes.resetButton}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
        <Grid
          alignItems="flex-end"
          item
          container
          md={4}
          sm={6}
          spacing={2}
          xs={12}
        >
          <Grid container item xs={12}>
            <Grid item xs={3}>
              <Box className={classes.Total}>Total:</Box>
            </Grid>
            <Grid item xs={9}>
              <Tooltip arrow title="* If applicable">
                <Typography className={classes.Sum} variant="subtitle1">
                  {isCalculating ? (
                    <>
                      <strong>Calculating ...</strong>
                      <sup></sup>
                    </>
                  ) : (
                    <>
                      <strong>€{cost} + VAT</strong>
                      <sup>*</sup>
                    </>
                  )}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <Button
              color="secondary"
              disabled
              fullWidth
              type="submit"
              variant="contained"
            >
              Email Quote
            </Button>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
}
