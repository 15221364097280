export default {
  volume: {
    value: '',
    measurement: 'cubicfoot',
  },
  origin: '',
  destination: '',
  service: 'dedicated',
  sku: {
    isLongWalkSelected: false,
    isShuttleVanSelected: false,
    isStorageSelected: false,
    isOutsideElevatorSelected: false,
    isStairsCarrySelected: false,
    isLiftSelected: false,
    isDoubleWardrobeSelected: false,
    isTripleWardrobeSelected: false,
    isTrampolineSelected: false,
    isHandyManSelected: false,
    handyManDuration: 'assembly:handy_man_half_day',
    shuttleVanDuration: 'access:shuttle_van_half_day',
    outsideElevatorDuration: 'access:outside_elevator_half_day',
    packingType: 'package:owner_packed',
    'package:book_carton': '',
    'package:standard_carton': '',
    'package:large_carton': '',
    'package:linen_carton': '',
    'package:wardrobe_carton': '',
    'package:custom_unpacking': false,
    'package:export_wrap': {
      value: 0,
      measurement: 'cubicfoot',
    },
    'package:unpacking': false,
    'access:stairs_carry': '',
    'access:lift': '',
    'access:long_walk': '',
    'access:parking_permit': false,
    'pianos_and_safes:upright_piano': false,
    'pianos_and_safes:baby_grand_piano': false,
    'pianos_and_safes:grand_piano': false,
    'pianos_and_safes:safe_up_to_200_kg': false,
    'pianos_and_safes:safe_up_to_350_kg': false,
    'vehicles:motorbike': false,
    'vehicles:car': false,
    'vehicles:ride_on_lawnmower': false,
    'storage:weeks': '',
    'storage:handling_in_out': false,
    'assembly:double_wardrobe': '',
    'assembly:triple_wardrobe': '',
    'assembly:trampoline': '',
  },
  discounts: {
    pay_on_collection: false,
  },
  termsAccepted: false,
};
