import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Chip, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  underlined: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  Chip: {
    background: '#398C19',
  },
  Paper: {
    backgroundColor: theme.palette.white,
    padding: 20,
  },
  supportedCountries: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0.5),
      fontWeight: 'bold',
    },
  },
}));

function SupportedCountries({ countries }) {
  const classes = useStyles();

  if (!Array.isArray(countries) || countries.length === 0) {
    return null;
  }

  return (
    <Box mb={4}>
      <Paper className={classes.Paper}>
        <Typography className={classes.underlined} gutterBottom variant="h5">
          SUPPORTED COUNTRIES
        </Typography>
        <Box className={classes.supportedCountries}>
          {countries.map(country => (
            <Chip
              className={classes.Chip}
              size="small"
              key={country}
              label={country.toUpperCase()}
              color="secondary"
            />
          ))}
        </Box>
      </Paper>
    </Box>
  );
}

SupportedCountries.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.string),
};

export default SupportedCountries;
