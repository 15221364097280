import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(5),
    '& h2': {
      marginBottom: '24px'
    },
    '& h4': {
      margin: '24px 0 18px'
    },
    '& ol': {
      marginBottom: '15px',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      lineHeight: '21px',
      color: '#263238',
      letterSpacing: '-0.05px',
      '& ol': {
        marginLeft: '16px'
      }
    },
  }
}));

const TermsAndConditions = () => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.root}
      container
      justify="center"
    >
      <Grid
        item
        lg={8}
        md={10}
        xs={12}
      >
        <Typography
          align="center"
          gutterBottom
          variant="h2"
        >
          TERMS & CONDITIONS
        </Typography>
        <Typography
          gutterBottom
          variant="body1"
        >
          These conditions explain your rights, obligations, and responsibilities and those of your contractor. A
          contract is a two-way arrangement and it is important that everyone knows where they stand. Where we use the
          word ‘you’ or ‘your’ it means the customer: ‘we’, ‘us’ or ‘our’ means the contractor named on the front of
          this contract. These conditions can only be changed or amended by our written agreement.
        </Typography>
        <ol
          className={classes.agreement}
          type="number"
        >
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Our Quotation – Our quotation is a fixed price. Unless otherwise stated, it does not include
              insurance, customs duties and inspections or any other fees payable to government bodies.
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
            >
              We may change the price or make additional charges if any of the following have not been taken into
              account
              when preparing our quotation and confirmed by us in writing.
            </Typography>
            <ol type="a">
              <li>
                You do not accept it in writing within 28 days, with a firm removal date to which we agree in writing.
              </li>
              <li>By your delay, the work is not carried out or completed within three months.</li>
              <li>
                Our costs increase (or decrease) because of currency fluctuations or changes in taxation or freight
                charges beyond our control.
              </li>
              <li>The work is carried out on a Saturday, Sunday or Public Holiday at your request.</li>
              <li>We have to collect or deliver goods above the ground and first upper floor.</li>
              <li>
                We supply any additional services, including moving or storing extra goods (these conditions apply to
                such work).
              </li>
              <li>
                The stairs, lifts or doorways are inadequate for free movement of the goods without mechanical equipment
                or structural alteration, or the approach, road or drive is unsuitable for our vehicles and/or
                containers
                to load and/or unload within 20 metres of the doorway, and this means we have to do extra work.
              </li>
              <li>
                Any parking or other fees or charges that we have to pay in order to carry out services on your
                behalf.
              </li>
              <li>There are delays or events outside our reasonable control.</li>
              <li>We agree in writing to increase our limit of liability set out in clause</li>
              <li>In all these circumstances, you will pay the adjusted charges.</li>
            </ol>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Work not included in the quotation – Unless agreed by us in writing we will not:
            </Typography>
            <ol type="a">
              <li>Dismantle or assemble unit or system furniture (flat-pack), fitments or fittings.</li>
              <li>Disconnect or reconnect appliances, fixtures, fittings or equipment.</li>
              <li>Take up or lay fitted floor coverings.</li>
              <li>Move storage heaters unless they are dismantled.</li>
              <li>Move items from a loft, unless properly lit, and floored and safe access is provided.</li>
              <li>Move or store any items excluded under clause 4.</li>
              <li>
                Our staff are not authorised or qualified to carry out such work. We recommend that a properly qualified
                person is separately employed by you to carry out these services.
              </li>
            </ol>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Your responsibility – It will be your sole responsibility to:
            </Typography>
            <ol type="a">
              <li>Declare to us the value of the goods being removed and/or stored.</li>
              <li>
                Arrange adequate insurance cover for the goods submitted for removal transit and/or storage, against all
                insurable risks.
              </li>
              <li>
                Obtain at your own expense, all documents, permits, licences, customs documents necessary for the
                removal to be completed.
              </li>
              <li>Be present or represented throughout the removal.</li>
              <li>
                Take all reasonable steps to ensure that nothing that should be removed is left behind and nothing is
                taken away in error.
              </li>
              <li>
                Arrange proper protection for goods left in unoccupied or unattended premises, or where other people
                such as (but<br />not limited to) tenants or workmen are, or will be present.
              </li>
              <li>Prepare adequately and stabilise all appliances or electronic equipment prior to their removal.</li>
              <li>
                Empty, properly defrost and clean refrigerators and deep freezers. We are not responsible for the
                contents.
              </li>
              <li>
                Provide us with a contact address for correspondence during removal transit and/or storage of goods.
              </li>
            </ol>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Goods not to be submitted for removal or storage – The following items are specifically excluded from this
              contract.
            </Typography>
            <ol type="a">
              <li>
                Jewellery, watches, trinkets, precious stones or metals, money, deeds, securities, stamps, coins, or
                goods or collections of any similar kind.
              </li>
              <li>
                Prohibited or stolen goods, drugs, potentially dangerous, damaging or explosive items, including gas
                bottles, aerosols, paints, firearms and ammunition.
              </li>
              <li>Plants or goods likely to encourage vermin or other pests or to cause infestation.</li>
              <li>Refrigerated or frozen food or drink.</li>
              <li>Any animals and their cages or tanks including pets, birds or fish.</li>
              <li>Goods which require special licence or government permission for export or import.</li>
            </ol>
            <Typography
              gutterBottom
              variant="body1"
            >
              Such goods will not be removed by us except with our prior written agreement. In the event that we do
              remove
              such goods, we will not accept liability for loss or damage wholly or mainly attributable to the special
              nature of the goods concerned. If you submit such goods without our knowledge and prior written agreement
              we
              will not be liable for any loss or damage and you will indemnify us against any charges, expenses, damages
              or penalties claimed against us. In addition, we shall be entitled to dispose of (without notice) any such
              goods which are listed under paragraphs 4(b),<br />4(c) or 4 (d).
            </Typography>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Ownership of the goods – By entering into this contract, you declare that:
            </Typography>
            <ol type="a">
              <li>The goods to be removed and/or stored are your own property, or</li>
              <li>
                The person(s) who own or have an interest in them, have given you authority to make this contract, and
                have been made aware of these conditions. You will meet any claim for damages and/or costs against us if
                these declarations are not true.
              </li>
            </ol>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Charges if you postpone or cancel the removal – If you postpone or cancel this contract, we may charge
              according to how much notice is given.
            </Typography>
            <ol type="a">
              <li>More than 14 days before the removal was due to start: NIL.</li>
              <li>
                Less than 14 days, but more than 8 days before the removal was due to start: 30% of the removal charge.
                (c) Less than 8 days before the removal was due to start: 60% of the removal charge.
              </li>
            </ol>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Paying for the Removal – Unless otherwise agreed by us in writing:
            </Typography>
            <ol type="a">
              <li>Payment is required, by cleared funds in advance of the removal or storage period.</li>
              <li>You may not withhold any part of the agreed price.</li>
              <li>
                In respect of all sums which are overdue to us, we will charge interest calculated at 4% above the base
                rate for the time being of Allied Irish Bank.
              </li>
            </ol>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Our liability for loss or damage – Our liability for loss or damage is limited, as set out in clause 8(a)
              below. You may request us to increase our liability, as set out in clause 8(c) below, subject to our
              express
              written agreement in advance of carrying out the removal and/or storage and payment of an additional
              charge.
            </Typography>
            <ol type="a">
              <li>
                In the event of our losing or damaging your goods, if we are liable, we will pay you up to a maximum sum
                of<br />€5.00 for each item which is lost or damaged, to cover the cost of repairing or replacing that
                item. OR
              </li>
              <li>
                We may choose to repair or replace the damaged item. If an item is repaired we are not liable for
                depreciation in value.
              </li>
              <li>
                Always subject to receiving your itemised valued inventory and request in writing to increase our
                liability, prior to the removal and/or storage commencing, we may offer to extend our maximum liability
                to the value declared by you to us. This is not an insurance on the goods and you are strongly advised
                to accept the insurance offered in our quote or if arranging insurance cover yourself, you are advised
                to show this contract to your insurance company. For the purposes of clauses 8(a), 8(b) and 8(c), an
                item is defined as any one article, suite, pair, set, complete case, carton, package or other container.
              </li>
              <li>
                <Typography
                  gutterBottom
                  variant="body1"
                >
                  Other than by reason of our negligence, we will not be liable for any loss, damage or failure to produce
                  the goods if it is caused by those circumstances set out in the following:
                </Typography>
                <ol type="i">
                  <li>By fire howsoever caused.</li>
                  <li>
                    By war, invasion, acts of foreign enemies, hostilities (whether war is declared or not), civil war,
                    terrorism, rebellion and/or military coup, Act of God, industrial action or other such events
                    outside
                    our reasonable control.
                  </li>
                  <li>
                    By normal wear and tear, natural or gradual deterioration, leakage or evaporation or from perishable
                    or unstable goods. This in cludes goods left within furniture or appliances.
                  </li>
                  <li>By moth or vermin or similar infestation.</li>
                  <li>By cleaning, repairing or restoring unless we did the work.</li>
                  <li>
                    To any goods in wardrobes, drawers or appliances, or in a package, bundle, case or other container
                    not both packed and unpacked by us.
                  </li>
                  <li>
                    For electrical or mechanical derangement to any appliance, instrument or equipment unless there is
                    evidence of external impact.
                  </li>
                  <li>
                    To jewellery, watches, trinkets, precious stones or metals, money, deeds, securities, stamps, coins,
                    or goods or collections of a similar kind, howsoever caused, unless you have previously given us
                    full
                    particulars with value, and we have confirmed in writing that we accept responsibility as in
                    conditions 8(a) or 8(c) above.
                  </li>
                  <li>To any goods which have a relevant proven defect or are inherently defective</li>
                  <li>To animals and their cages or tanks including pets, birds or fish. (xi) To plants.</li>
                  <li>To refrigerated or frozen food or drink.</li>
                </ol>
              </li>
              <li>
                Other than by reason of our negligence, we will not be liable for damages or costs resulting indirectly
                from, or as a consequence of loss, damage, or failure to produce the goods.
              </li>
              <li>
                No employee of the contractor named on the front of this contract shall be separately liable to you for
                any loss, damage, mis-delivery, errors or omissions under the terms of this contract.
              </li>
            </ol>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Extra conditions that apply to removals to/from a foreign country outside the Republic of Ireland : For
              goods destined to, or received from a place outside the Republic of Ireland.
            </Typography>
            <ol type="a">
              <li>
                We will accept liability only for loss or damage to goods when they are in our actual possession,
                and if it can be proven that we were negligent. In such circumstances, we will accept liability as in
                condition 8(a) or 8(c) above. We are not liable for any loss, damage or failure to produce the goods if
                it
                is caused by those circumstances set out in condition 8(d) above.
              </li>
              <li>
                Where we engage an international transport operator, shipping company or airline, to convey your goods
                to the place, port or airport of destination, we do so on your behalf and subject to the terms and
                conditions set out by that carrier.
              </li>
              <li>
                If the carrying vessel/conveyance, should for reasons beyond the carrier’s control, fail to deliver the
                goods, or route them to a place other than the original destination, you have limited recourse against
                the
                carrier, and may be liable for General Average contribution (e.g. the costs incurred to preserve the
                vessel/conveyance and cargo) and salvage charges, or the additional cost of on ward transmission to the
                place, port or airport of destination. These are insurable risks and it is your responsibility to
                arrange
                adequate marine/transit insurance cover.
              </li>
              <li>
                We do not accept liability for goods confiscated, seized or removed by Customs Authorities or
                other<br />Government Agencies.
              </li>
            </ol>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Time limit for claims.
            </Typography>
            <ol type="a">
              <li>
                For goods which we deliver, you must note any visible loss, damage or failure to produce any goods at
                the time of delivery.
              </li>
              <li>
                If you or your agent collect from our warehouse, you must note any loss or damage at the time the goods
                are handed to you.
              </li>
              <li>
                Notwithstanding clause 8, we will not be liable for any loss of or damage to the goods unless a claim is
                notified<br />to us or our foreign correspondent in writing as soon as such loss or damage is discovered
                (or with reasonable diligen ce ought to have been discovered) and in any event within seven (7) days of
                collection of the goods from us or delivery of the goods by us, as the case may be.
              </li>
              <li>
                The time limits referred to in clauses 10(a), 10(b) and 10 (c) above shall be essential to the
                contract.
              </li>
              <li>
                Upon your written request we may at our discretion agree to extend your time for compliance with clause
                10 (c), PROVIDED your request is received within the time limits provided for and subject to this
                proviso
                we will not unreasonably refuse such a request.
              </li>
            </ol>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Delays in transit
            </Typography>
            <ol type="a">
              <li>Other than by reason of our negligence, we will not be liable for delays in transit.</li>
              <li>
                If through no fault of ours we are unable to deliver your goods, we will take them into store. The
                contract will then be fulfilled and any additional service(s), including storage and delivery, will be
                at
                your expense.
              </li>
            </ol>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Damage to premises or property other than goods.
            </Typography>
            <ol type="a">
              <li>
                We will not be liable for any damage to premises or property other than goods submitted for removal
                and/or storage unless we have been negligent.
              </li>
              <li>
                If we cause damage as a result of moving goods under your express instruction, against our advice, and
                where to<br />move the goods in the manner instructed will inevitably cause damage, we shall not accept
                that we were negligent.
              </li>
              <li>
                If we are responsible for causing damage to your premises or to property other than goods submitted for
                removal and/or storage, you must note this on the worksheet or deliver y receipt. This is essential to
                the
                contract.
              </li>
            </ol>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Our Right to Hold the Goods (lien)
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
            >
              We shall have a right to withhold and/or ultimately dispose of some or all of the goods until you have
              paid
              all our charges and any other payments due under this or any other contract. These include any charges
              that
              we have paid out on your behalf. While we hold the goods and wait for payment you will be liable to pay
              all
              storage charges and other costs incurred by our withholding your goods and these terms and conditions
              shall
              continue to apply.
            </Typography>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Our right to sub-contract the work.
            </Typography>
            <ol type="a">
              <li>We reserve the right to sub-contract some or all of the work.</li>
              <li>If we sub-contract, then these conditions will still apply.</li>
            </ol>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Route and method
            </Typography>
            <ol type="a">
              <li>We have the full right to choose the route for delivery.</li>
              <li>
                Unless it has been specifically agreed in writing on our Quotation, other space/volume/capacity on our
                vehicles and/or the container may be utilised for consignments of other customers.
              </li>
            </ol>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Advice and information
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
            >
              Advice and information in whatever form it may be given is provided by the company for the customer only.
              Any oral advice given without special arrangement is provided gratuitously and without contractual
              liability.
            </Typography>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Applicable law
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
            >
              This contract is subject to the law of the country in which the office of the company issuing this
              contract
              is situated.
            </Typography>
          </li>
        </ol>
        <Typography
          gutterBottom
          variant="h4"
        >
          EXTRA CONDITIONS THAT APPLY TO THE STORAGE OF GOODS
        </Typography>
        <ol
          start={18}
          type="number"
        >
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Your forwarding address
            </Typography>
            <ol type="a">
              <li>
                If you send goods to be stored you must provide an address for letters and notify us if it changes. All
                correspondence and notices will be considered to have been received by you seven days after posting it
                to
                your last address recorded by us.
              </li>
              <li>
                If you do not provide an address or respond to our correspondence or notices, we may publish such
                notices in a public newspaper in the area to or from which the goods were removed.
              </li>
            </ol>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              List of goods (inventory) or receipt
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
            >
              Where we produce a list of your goods (inventory) or a receipt and send it to you, it will be accepted as
              accurate unless you write to us within seven days of receiving it, notifying us of any errors or
              omissions.
            </Typography>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Revision of storage charges
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
            >
              We review our storage charges periodically. You will be given 28 days notice in writing of any
              increases.
            </Typography>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Our right to Sell or dispose of the Goods.
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
            >
              On giving you 28 days notice we are entitled to require you to remove your goods from our custody and pay
              all money due to us. If you fail to pay all outstanding debts due to us we are entitled to sell or dispose
              of some or all the goods without further notice. The cost of the sale or disposal will be charged to you.
              The net proceeds will be credited to your account and any eventual surplus will be paid to you without
              interest.
            </Typography>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Termination
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
            >
              If payments are up to date we will not end this contract except by giving you three calendar months notice
              in writing. If you wish to terminate your storage contract, you must give us at least 14 days notice. If
              we can release the goods earlier, we will do so, provided that your account is paid up to date. Charges
              for storage are payable to the date when the notice should have taken effect.
            </Typography>
          </li>
          <li>
            <Typography
              gutterBottom
              variant="h5"
            >
              Hand out charges
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
            >
              If you make your own arrangements to collect the goods from our warehouse we are entitled to make a charge
              for handing them over. Our liability will cease upon handing over the goods.
            </Typography>
          </li>
        </ol>
      </Grid>
    </Grid>
  );
};

export default TermsAndConditions;
