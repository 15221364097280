import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import * as yup from 'yup';

import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';

VolumeForm.schema = yup
  .object()
  .shape({
    value: yup
      .number()
      .typeError('Please enter package volume')
      .moreThan(0, 'Package volume should be greater than 0')
      .round('ceil')
      .required('Please enter package volume'),
    measurement: yup.string(),
  })
  .required('Please enter package volume');

export default function VolumeForm({ name, ...props }) {
  const { errors, control, register } = useFormContext();

  return (
    <Box {...props}>
      <Grid
        alignItems="center"
        container
        item
        direction="row"
        justify="flex-start"
        spacing={2}
      >
        <Grid item md={4} sm={5} xs={6}>
          <TextField
            fullWidth
            color="secondary"
            variant="outlined"
            name={`${name}.value`}
            inputRef={register}
            label="Volume"
            size="small"
            type="number"
            inputProps={{ step: '0.01' }}
            error={Boolean(errors.volume)}
            helperText={
              Boolean(errors.volume) &&
              Boolean(errors.volume.value) &&
              errors.volume.value.message
            }
          />
        </Grid>
        <Grid container item md={8} sm={7} xs={6}>
          <FormControl component="fieldset">
            <Controller
              name={`${name}.measurement`}
              control={control}
              as={
                <RadioGroup aria-label="volume">
                  <Grid container>
                    <Grid item>
                      <FormControlLabel
                        control={<Radio color="primary" />}
                        label="Cu. Ft."
                        value="cubicfoot"
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={<Radio color="primary" />}
                        label="Cu. M."
                        value="cubicmeter"
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
