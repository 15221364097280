import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const Profile = props => {
  const { className, ...rest } = props;
  const [username, setUsername] = useState();
  const [givenName, setGivenName] = useState();
  const [familyName, setFamilyName] = useState();

  const classes = useStyles();

  useEffect(() => {
    try {
      Auth.currentUserInfo()
        .then(user => {
          setUsername(user.attributes.username);
          setGivenName(user.attributes.given_name);
          setFamilyName(user.attributes.family_name);
          console.log(`Load settings for user: ${user.username}`);
        })
        .catch(err => console.log(err));
    } catch (e) {
      console.log(e);
    }
  }, [username, givenName, familyName]);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar alt={username} variant="rounded" />
      <Typography className={classes.name} variant="h4">
        {givenName} {familyName}
      </Typography>
      <Typography variant="body2"></Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
