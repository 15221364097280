import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import {
  FormControl,
  FormControlLabel,
  Grid,
  Box,
  Radio,
  RadioGroup,
} from '@material-ui/core';

export default function RouteTypeForm(props) {
  const { control } = useFormContext();

  return (
    <Box {...props}>
      <FormControl component="fieldset" fullWidth>
        <Controller
          name="routeType"
          control={control}
          as={
            <RadioGroup aria-label="Route Type">
              <Grid container justify="space-between">
                <Grid item md={3} sm={6} xs={12}>
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Residence to Residence"
                    value="RTR"
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Residence to Warehouse"
                    value="RTW"
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Warehouse to Residence"
                    value="WTR"
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Warehouse to Warehouse"
                    value="WTW"
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          }
        />
      </FormControl>
    </Box>
  );
}
