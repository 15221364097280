import httpClient from './httpClient';
import { Auth } from 'aws-amplify';

const quoteRoutes = {
  async generateQuote(payload) {
    try {
      let session = await Auth.currentSession();
      let idToken = session.idToken.jwtToken;
      let headers = {
        Authorization: `${idToken}`,
      };
      return httpClient.post('/quote', payload, {
        headers: headers,
      });
    } catch (e) {
      console.log(e);
    }
  },
};

export default {
  ...quoteRoutes,
};
